import Honeybadger from '@honeybadger-io/js';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import getWedding from '@utils/getWedding';
import { noopBoolean } from '@utils/noop';
import { CookieStorage } from 'cookie-storage';
import React, { createContext, FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import type { Membership, State } from 'types/redux';
import type { Decorated } from 'types/vendor';
import { XO_SESSION_TOKEN } from '../../../constants/membership';
import AlertMessage, { AlertMessageRef } from './alertMessage';
import { trackAddToSaved } from './analytics';
import Modal, { ModalRef } from './modal';
import { LocalSaves, WeddingsContextValue } from './types';
import { createSave, fetchSaves, removeSave } from './utils';

interface WeddingsContextProviderProps {
	memberId: string;
	membership: Membership;
	maxResultColumns: number;
	storefront: Decorated;
}

const Context = createContext<WeddingsContextValue>({
	getIsSaved: noopBoolean,
	onSaveHandler: async () => {
		/* init context function */
	},
	getMaxResultColumns: () => {
		return 3;
	},
	didSaveFail: false,
});

export const Provider: FC<WeddingsContextProviderProps> = (props) => {
	const { children, memberId, membership, maxResultColumns, storefront } =
		props;
	const [weddingId, setWeddingId] = useState<string | null>(null);
	const [savedVendorIds, setSavedVendorIds] = useState<LocalSaves>({});
	const [potentialSave, setPotentialSave] = useState<Vendor.Raw | null>(null);
	const [didSaveFail, setDidSaveFail] = useState<boolean>(false);
	const saveSource = useRef<string | undefined>('');
	const alertRef = useRef<AlertMessageRef>(null);
	const modalRef = useRef<ModalRef>(null);
	const { track } = useAnalyticsContext();

	const cookieStorage = new CookieStorage();
	const sessionToken = cookieStorage.getItem(XO_SESSION_TOKEN) || undefined;

	// biome-ignore lint/correctness/useExhaustiveDependencies: dependencies are correct
	useEffect(() => {
		getSaves();
	}, [membership.member, sessionToken]);

	const getWeddingId = async (): Promise<string> => {
		if (weddingId) {
			return weddingId;
		}

		if (!sessionToken || !memberId) {
			// User is not logged in
			return '';
		}

		try {
			const response = await getWedding(memberId, sessionToken);
			const { wedding } = response;

			if (!wedding) {
				const error = new Error('No wedding found');
				Honeybadger.notify(error, 'Error getting wedding id');
				return '';
			}

			setWeddingId(wedding.id);
			return wedding.id;
		} catch (error) {
			Honeybadger.notify(error, 'Error getting wedding id');
		}

		return '';
	};

	const getSaves = async (): Promise<LocalSaves> => {
		const memberWeddingId = await getWeddingId();
		const savesMapping = await fetchSaves(sessionToken, memberWeddingId);
		setSaves(savesMapping);
		return savesMapping;
	};

	const setSaves = async (savesMapping: LocalSaves) => {
		setSavedVendorIds(savesMapping);

		if (potentialSave && !savesMapping[potentialSave.id]) {
			await saveVendor(potentialSave, true);
			setPotentialSave(null);
		}
	};

	const showAlert = <T,>(
		vendor: Vendor.Raw,
		isFailure: boolean,
		response: T,
		showSnackbar?: boolean,
	) => {
		if (showSnackbar && alertRef?.current) {
			alertRef.current.show(vendor, isFailure, response);
		}
	};

	const saveVendor = async (vendor: Vendor.Raw, showSnackbar?: boolean) => {
		const memberWeddingId = await getWeddingId();

		const save = await createSave(vendor, sessionToken, memberWeddingId);

		if (typeof save === 'undefined') {
			setDidSaveFail(true);
			saveSource.current = '';
			showAlert<null>(vendor, true, null, showSnackbar);
			return;
		}

		setSavedVendorIds((prev) => ({
			...prev,
			[save.vendorId]: save.saveId,
		}));

		trackAddToSaved(vendor, track, memberId, saveSource.current, storefront);
		saveSource.current = '';
		setDidSaveFail(false);
		showAlert<null>(vendor, false, null, showSnackbar);
		setPotentialSave(null);
	};

	const unsaveVendor = async (vendor: Vendor.Raw) => {
		const memberWeddingId = await getWeddingId();
		const saveId = savedVendorIds[vendor.id];
		await removeSave(saveId, sessionToken, memberWeddingId);

		setSavedVendorIds((prev) => {
			const { [vendor.id]: _, ...rest } = prev;
			return rest;
		});
	};

	const isLoggedIn = membership.member?.id && sessionToken;

	const onSaveHandler = async (
		vendor: Vendor.Raw,
		source?: string,
		showSnackbar = true,
	) => {
		saveSource.current = source;

		if (isLoggedIn) {
			const isSavedVendor = getIsSaved(vendor.id);
			if (isSavedVendor) {
				await unsaveVendor(vendor);
				saveSource.current = '';
				return;
			}

			await saveVendor(vendor, showSnackbar);
			return;
		}

		setPotentialSave(vendor);
		if (modalRef.current) {
			modalRef.current.setOpenState('SIGN_UP');
		}
	};

	const getIsSaved = (vendorId: string) =>
		!!isLoggedIn && !!savedVendorIds[vendorId];

	const getMaxResultColumns = () => maxResultColumns;

	return (
		<Context.Provider
			value={{
				didSaveFail,
				getIsSaved,
				getMaxResultColumns,
				onSaveHandler,
			}}
		>
			{children}
			<Modal ref={modalRef} />
			<AlertMessage ref={alertRef} membership={membership} />
		</Context.Provider>
	);
};

const mapStateToProps = (state: State) => ({
	maxResultColumns: state.search.maxResultColumns,
	membership: state.membership,
	storefront: state.vendor.vendor,
	memberId: state.membership.member ? state.membership.member.id : '',
});

export const WeddingsContextProvider = connect(mapStateToProps)(Provider);

export default Context;
