import MembershipModalRedux, {
	OpenModalType,
} from '@xo-union/tk-component-membership-modal-redux';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';

export interface ModalRef {
	setOpenState(type: OpenModalType): void;
}

const Modal = forwardRef<ModalRef>((_props, ref) => {
	const [openState, setOpenState] = useState<OpenModalType | undefined>();

	useImperativeHandle(ref, () => ({
		setOpenState,
	}));

	const element =
		typeof document !== 'undefined' && document?.getElementById('app');

	const onClickLogIn = () => {
		setOpenState('LOG_IN');
	};

	const onClickSignUp = () => {
		setOpenState('SIGN_UP');
	};

	const closeModal = () => {
		setOpenState(undefined);
	};

	if (!element) {
		return null;
	}

	return createPortal(
		<MembershipModalRedux
			metadata={{
				userAction: 'favorites button',
				applicationName: 'marketplace',
			}}
			openModalType={openState}
			onClickLogIn={onClickLogIn}
			onClickSignUp={onClickSignUp}
			onSignUpSuccess={closeModal}
			onLogInSuccess={closeModal}
			onClose={closeModal}
		/>,
		element,
	);
});

export default Modal;
