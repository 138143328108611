import { Track } from 'types/analytics';
import AnalyticsConstants from '../../../constants/analytics';
import Vendor from '../../decorators/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';

export const trackAddToSaved = (
	vendor: Vendor.Raw,
	track: Track,
	memberId: string,
	source?: string,
	storefront?: Vendor.Decorated,
) => {
	const vendorAnalytics = new VendorAnalytics(new Vendor(vendor));

	const { ADD_TO_FAVORITE, CATEGORY_RESULTS, SEARCH } = AnalyticsConstants;

	track({
		event: ADD_TO_FAVORITE,
		properties: {
			memberId,
			sourcePage: CATEGORY_RESULTS,
			sourceContent: source,
			userDecisionArea: SEARCH,
			...vendorAnalytics.buildEventProps(),
		},
	});
};

export const trackSavedVendorListClick = (track: Track) => {
	const { CATEGORY_RESULTS, CLICK_THROUGH_TO_VENDOR_LIST, FAVORITED_VENDOR } =
		AnalyticsConstants;

	track({
		event: CLICK_THROUGH_TO_VENDOR_LIST,
		properties: {
			sourcePage: CATEGORY_RESULTS,
			sourceContent: FAVORITED_VENDOR,
		},
	});
};
